import {Box, Container, Grid, Theme, Typography, useMediaQuery} from "@material-ui/core";
import { str } from "utils";
import {getImage} from "../assets";

const PrizesSection: React.FC = () => {

  const xs = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));

  const PrizeBlock = ({ title, img, name }: { title: string; img: string; name: string }) => {
    return (
        <Box style={{ height: '100%' }}>
          <Box
              fontWeight={900}
              fontSize={{ xs: 16, sm: 16, md: 24 }}
              textAlign="center"
              mb={4}
              style={{ textTransform: 'uppercase' }}
          >
            {str.normalize(title)}
          </Box>
          <Box bgcolor="#FFF" p={4} height="calc(100% - 120px)" mx="auto" width="80%">
            <Grid container justifyContent="center" alignContent="flex-end" style={{ height: '100%' }}>
              <Grid item xs={12} style={{ flex: '1 0 auto' }}>
                <Grid container style={{ height: '100%' }} alignItems="center">
                  <Grid item xs={12}>
                    <Box mb={2} mx="auto">
                      <img draggable={false} src={img} alt="" style={{ maxWidth: '100%', margin: '0 auto' }} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ flex: '0 1 auto' }} xs={12}>
                <Box
                    fontSize={{ xs: 14, sm: 20, lg: 22 }}
                    textAlign="center"
                    style={{ textTransform: 'uppercase' }}
                    fontWeight={700}
                    pb={2}
                >
                  {str.normalize(name)}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
    )
  }

  return (
      <Box py={{ xs: 5, sm: 10, md: 12 }} bgcolor="#F2D1A8">
        <Container maxWidth="xl">
          <Box fontSize={{ xs: 24, md: 45 }} pb={1} pt={5}>
            <Typography variant="h2" style={{ marginTop: 0, fontSize: 'inherit', textTransform: 'uppercase' }}>
              Призовой фонд
            </Typography>
          </Box>
          <Box fontSize={{ xs: 20, sm: 20, md: 36 }} pb={3}>
            <Grid container alignItems={'stretch'} justifyContent={'center'} spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <PrizeBlock
                    key="123sad"
                    title={'1 место'}
                    name={'Набор: Лежанка, поилка и&nbsp;игрушка'}
                    img={getImage?.['p1.png']}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <PrizeBlock
                    key="2132123"
                    title={'2 место'}
                    name={'Запас корма Alphapet<sup>®</sup> на 3 месяца'}
                    img={getImage?.['p2.png']}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <PrizeBlock title={'3 место'} name={'Запас корма Alphapet<sup>®</sup> на 1 месяц'} img={getImage?.['p3.png']} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box pt={{xs:0, md:4}}>
                <PrizeBlock title={'Еженедельный приз от организатора'} name={'Специальный приз <br/><br/>'} img={getImage?.['p4.png']} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
  )
};

export default PrizesSection;
