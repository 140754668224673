import { useEffect, useRef, useState } from 'react'
import { Box, Container, Grid, useMediaQuery, Typography, Theme, Fade, CircularProgress } from '@material-ui/core'
import { PlayArrow } from '@material-ui/icons'
import { ImageRenderer } from 'components'
import { promoGet } from 'api/promo'
import { useIntersection, useReduxDispatch } from 'hooks'
import { ApiAnswerStatus, PromoUserGalleryItem, PromoUserWinner } from 'types'
import { propsAction } from 'store/props'
import moment from 'moment'
import { str } from 'utils'
import { mediaViewerModalName } from '../modals'
import { useStyles } from '../promo.style'
import { WinnerList } from './winner-list'

const WinnersSection: React.FC = () => {
  const classes = useStyles()
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xs'))

  const dispatch = useReduxDispatch()

  const [inView, setInView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [winners, setWinners] = useState<PromoUserWinner[]>([])

  const winnersRef = useRef<HTMLDivElement>(null)

  useIntersection(winnersRef, () => {
    if (!inView) setInView(true)
  })

  const fetchWinners = async () => {
    setLoading(true)
    const { data, status } = await promoGet('winner')
    setLoading(false)

    if (status === ApiAnswerStatus.SUCCESS && typeof data === typeof winners) {
      setWinners(data)
    }
  }

  useEffect(() => {
    if (inView) fetchWinners()
  }, [inView])

  const showGalleryItemModal = (params?: PromoUserGalleryItem) => {
    dispatch(
      propsAction.modal({
        modal: { [mediaViewerModalName]: { show: true, params: { ...params, hideVote: true } } },
      })
    )
  }

  return (
    <Box py={{ xs: 5, sm: 8 }}>
      <div ref={winnersRef}>
        <Container maxWidth="xl">
          <Box position="relative">
            <Box fontSize={{ xs: 24, md: 45 }}>
              <Typography variant="h2" style={{ marginTop: 0, fontSize: 'inherit', textTransform: 'uppercase' }}>
                Победители
              </Typography>
            </Box>

            <Grid container spacing={sm ? 2 : 4}>
              {winners?.map((item, idx) => {
                const { gallery, priz, created_at } = item

                return (
                  <Grid key={`winner-${idx}`} item xs={12} sm={6} md={4}>
                    <Grid container alignItems="stretch" style={{ height: '100%' }}>
                      <Grid item xs={12} sm={6}>
                        <Box
                          height={{ xs: 280, sm: '100%' }}
                          className={classes.galleryImage}
                          onClick={() => gallery?.content && showGalleryItemModal(gallery)}
                          title={moment(created_at).format('DD.MM.YYYY HH:mm')}
                        >
                          <ImageRenderer
                            url={gallery?.content?.thumb_src_link ?? ''}
                            thumb={gallery?.content?.small_src_link ?? ''}
                            cover
                          />

                          {!gallery?.content?.is_image && (
                            <Box className="video_overlay">
                              <PlayArrow fontSize="inherit" color="inherit" />
                            </Box>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box bgcolor="#F9F5F3" height="100%">
                          <Box p={{ xs: 2, sm: 5 / 2 }} height="100%" boxSizing="border-box">
                            <Grid container style={{ height: '100%' }}>
                              <Grid item xs={12}>
                                <Box fontSize={{ xs: 18, sm: 24 }} fontWeight={700} mb={2}>
                                  {gallery?.content?.pet_name ?? ''}
                                </Box>

                                <Box fontSize={{ xs: 12, sm: 14 }} fontWeight={600} color="primary.main">
                                  ПРИЗ
                                </Box>
                                <Box
                                  mb={3}
                                  fontWeight={600}
                                  fontSize={{ xs: 14, sm: 16 }}
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                >
                                  {str.normalize(priz?.name ?? '')}
                                </Box>
                              </Grid>

                              <Grid item xs={12} style={{ marginTop: 'auto' }}>
                                <Box fontSize={{ xs: 11, sm: 13 }} fontWeight={600} color="#8C9297">
                                  ТЕЛЕФОН ВЛАДЕЛЬЦА
                                </Box>
                                <Box fontSize={{ xs: 14, sm: 18 }} className="nowrap_txt">
                                  {str.format.nowrap(gallery?.user?.phone_secure ?? '')}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>

            {!loading && winners.length === 0 && (
              <Box textAlign="center">
                <Typography variant="body2">Ждем первых победителей</Typography>
              </Box>
            )}

            {/*{!loading && winners.length > 0 && (*/}
            {/*  <Grid container spacing={sm ? 2 : 4}>*/}
            {/*    /!*<WinnerList items={winners} />*!/*/}
            {/*  </Grid>*/}
            {/*)}*/}
            <Fade in={loading}>
              <Box
                position="absolute"
                textAlign="center"
                bgcolor="rgba(255,255,255,0.5)"
                left={0}
                top={0}
                bottom={0}
                right={0}
              >
                <Box mt={6}>
                  <CircularProgress />
                </Box>
              </Box>
            </Fade>
          </Box>
        </Container>
      </div>
    </Box>
  )
}

export default WinnersSection
