import {
  Box, Button,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { str } from "utils";
import { useReduxSelector } from "hooks";
import {getImage} from "../assets";

const RulesSection: React.FC = () => {
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));

  const { promoRulesSrc = "#" } = useReduxSelector((s) => s.props);

  const rules = [
    {
      src: getImage?.["r1.png"] ?? "",
      title: "Покупайте корм ТМ&nbsp;AlphaPet®",
      description: "",
    },
    {
      src: getImage?.["r2.png"] ?? "",
      title: "Регистрируйте чеки на сайте",
      description: "",
    },
    {
      src: getImage?.["r3.png"] ?? "",
      title:
        "Загружайте забавные фото и видео во время активного отдыха со своим питомцем на природе",
      description: "",
    },
    {
      src: getImage?.["r4.png"] ?? "",
      title: "Победителей определит генератор случайных чисел",
      description: "",
    },
  ];

  return (
    <Box
      py={{ xs: 5, sm: 8 }}
      id="rules"
      style={{ background: `url(${getImage?.["bg_rules.png"]})` }}
    >
      <Container maxWidth="xl">
        <Box fontSize={{ xs: 24, md: 45 }}>
          <Typography
            variant="h2"
            style={{
              marginTop: 0,
              fontSize: "inherit",
              textTransform: "uppercase",
            }}
          >
            Правила
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent={"space-around"}>
          {rules.map(({ src, title, description }, idx) => (
            <Grid key={`rule-${idx}`} item xs={10} sm={5} md={5} lg={3}>
              <Grid container justifyContent={sm ? "flex-start" : "center"}>
                <Grid item xs={12}>
                  <Box mb={2} mx="auto" maxWidth="90%" textAlign="center">
                    <img
                      draggable={false}
                      src={src}
                      alt=""
                      height="auto"
                      style={{ maxWidth: 250, width: "100%", margin: "0 auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box position="relative" mx="auto" maxWidth="90%">
                    <Box
                      zIndex={1}
                      position="absolute"
                      lineHeight={1}
                      left={-40}
                      top={{ xs: -20, lg: -20 }}
                      fontSize={{ xs: 60, sm: 80, md: 100, lg: 150 }}
                      fontWeight={900}
                      color="#AD7D66"
                    >
                      {idx + 1}
                    </Box>
                    <Box
                      position="relative"
                      pb={{ xs: 0.5, sm: 1 }}
                      pl={{ xs: 2, sm: 3.5, md: 4, lg: 4.5 }}
                      zIndex={2}
                      fontSize={{ xs: 16, sm: 18, md: 25 }}
                      fontWeight={600}
                      textAlign={"left"}
                    >
                      {str.normalize(title)}
                    </Box>
                    <Box fontWeight={400} fontSize={14} textAlign={"center"}>
                      {str.normalize(description)}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Box pt={3} fontWeight={600} fontSize={'0.9em'}>*Не участвуют фасовки 7,5 и 18 кг., а также линейка кормов AlphaPet® MENU.</Box>
        </Grid>
      </Container>

      <Box
          style={{ backgroundColor: '#F2D1A8'}}
          pt={5}
          mt={5}
      >
        <Container>
          <Grid container justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
            <Grid item xs={12} md={7}>
              <Box
                  color="#323E48"
                  mx="auto"
                  textAlign={sm ? 'center' : 'left'}
                  position="relative"
                  zIndex={2}
                  fontSize={{ xs: 16, sm: 34, lg: 34 }}
                  fontWeight={700}
                  pb={4}
              >
                {str.normalize('Каждую неделю самая креативная работа  по мнению Организатора получит специальный приз от него.')}
              </Box>

              <Box textAlign={sm ? 'center' : 'left'} mt={4}>
                <Button variant="contained" color="primary" href={promoRulesSrc} target="_blank">
                  Полные правила конкурса
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box  mb={3} mx="auto" maxWidth="100%" textAlign="center">
                <img
                    draggable={false}
                    src={getImage?.['p_main.png']}
                    alt=""
                    height="auto"
                    style={{ maxWidth: '100%', margin: '0 auto' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default RulesSection;
