import { makeStyles, Theme } from '@material-ui/core'
import {getImage} from "./assets";

export const useStyles = makeStyles(({ palette, breakpoints: { down, up }, spacing }: Theme) => ({
  root: {
    '& img': {
      display: 'block',
      maxWidth:"100%",
      pointerEvents: 'none',
    },
  },
  top: {
    width: '100%',
    marginTop: -94,
    overflow: 'hidden',
    position: 'relative',
    minHeight: '62vh',
    background: `url(${getImage?.["bg.jpg"] ?? ''}) center / cover no-repeat`,

    [up('sm')]: {
      '& h1': { textAlign: 'left', fontSize: 80 },
    },

    [down('sm')]: {
      marginTop: -0,
      '& h1': { textAlign: 'center', marginTop: 0, fontSize: 65 },
    },
    [down('xs')]: {
      '& h1': { textAlign: 'center', marginTop: 0,paddingBottom:"5em", fontSize: 35 },
      background: `url(${getImage?.["bg_m.png"] ?? ''}) center / cover no-repeat`,
    },
  },

  body: {
    paddingBottom: spacing(17),
    color: '#323E48',
    [down('sm')]: { paddingBottom: spacing(10) },
  },

  likeButton: {
    transition: 'color 0.15s ease-in-out',
    '&:hover': {
      color: palette.error.main,
    },
  },
  bullet: {
    textAlign: 'left',
    paddingLeft: '3em',
    listStyle: 'none',

    '& li': {
      position: 'relative',
      paddingBottom: 12,
      fontWeight: 400,
      '&::before': {
        content: "'\\25AA'",
        color: '#AD7D66',
        fontSize: '3em',
        lineHeight: 0.4,
        top: 0,
        fontWeight: 600,
        position: 'absolute',
        display: 'inline-block',
        wight: '1em',
        marginLeft: -25,
      },
    },
  },

  sortBtn: {
    outline: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    userSelect: 'none',
    '&.disabled': {
      cursor: 'initial',
      color: palette.text.disabled,
      '&:hover, &:active': { color: palette.text.disabled },
    },
    '&:hover': {
      color: palette.primary.main,
    },
    '&:active': {
      color: palette.primary.light,
    },
    '& svg, & span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },

  galleryImage: {
    position: 'relative',
    cursor: 'pointer',

    '& .video_overlay': {
      color: '#fff',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      fontSize: 60,
      backgroundColor: 'rgba(0,0,0,0.3)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& img': {
      transform: 'scale(1.007)',
    },

    '&:hover': {
      '& img': {
        transform: 'scale(1.025)',
      },
    },
  },

  dog: {
    bottom: 0,
    pointerEvents: 'none',
    zIndex: 0,
    width: '100%',
    position: 'absolute',
  },

  d: {
    width: '19vw',
    maxWidth: '350px',
    bottom: 0,
    position: 'absolute',
    marginLeft: '50%',
    '& img': {
      bottom: 0,
      width: '100%',
    },
    [down('xs')]: {
      width: '75vw',
    },
  },
  bg: {
    position: 'absolute',
    zIndex: 0,
    left: '50%',
    top: '50%',
    imageRendering: 'pixelated',
    pointerEvents: 'none',
    transform: 'translate(-50%,-50%) translateZ(0)',
  },


  btn:{
    backgroundColor:"#D75C55",
    padding:"2em 2em",

    "&:after":{
      content:'none'
    },
    "&:hover":{
      backgroundColor:"#E9AAA5!important",

    }
  }
}))
