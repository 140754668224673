import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Fade,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import useReactRouter from "use-react-router";
import { scroller } from "react-scroll";
import { useReduxDispatch, useReduxSelector } from "hooks";
import { Redirect } from "react-router-dom";
import { withPlanner, WithPlannerProps } from "components/hoc";
import { useStyles } from "./promo.style";
import { getImage } from "./assets";
import RulesSection from "./sections/rules";
import GallerySection from "./sections/gallery";
import PrizesSection from "./sections/prizes";
import WinnersSection from "./sections/winners";

const PromoPage: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const { isAuth } = useReduxSelector((state) => state.auth);
  const { promoAviable } = useReduxSelector((state) => state.props);

  const lg = useMediaQuery(({ breakpoints: { down } }: Theme) => down("md"));
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));
  const dispatch = useReduxDispatch();
  const [pvar, setPvar] = useState<number>(0);

  const handleClick = () => {
    if (isAuth) history.push({ pathname: "profile", hash: "action" });
    else history.push({ search: "signin" });
  };

  useEffect(() => {
    if (history.location.hash === "#contest_rules") {
      planner?.timeout(() => {
        scroller.scrollTo("contest_rules", {
          ignoreCancelEvents: true,
          activeClass: "active",
          duration: 700,
          hashSpy: true,
          smooth: true,
          offset: 0,
          spy: true,
        });
        history.push({ hash: "" });
      }, 150);
    }
  }, [history.location]);

  // useEffect(() => {
  //   dispatch(
  //       propsAction.alert({
  //         title: "Уважаемые участники!",
  //         message: {
  //           info: [
  //             // eslint-disable-next-line max-len
  //             `Регистрация чеков для участия в Акции завершена 19 мая 2024 года.`,
  //           ],
  //         },
  //
  //       })
  //   );
  // }, []);

  if (typeof promoAviable === "undefined")
    return (
      <Box my={4} textAlign="center">
        <CircularProgress />
      </Box>
    );

  if (promoAviable === false) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Fade in timeout={350}>
      <Box className={classes.root}>
        <Box className={classes.top}>
          <img
            src={getImage?.["bg.jpg"]}
            style={{ visibility: "hidden", width: "100%" }}
            alt=""
          />
          {!md && <Box className={classes.bg}></Box>}

          <Container
            maxWidth={false}
            style={{
              maxWidth: "100%",
              position: "absolute",
              top: 0,
              height: "100%",
              zIndex: 1,
            }}
          >
            <Grid
              container
              justifyContent={"flex-end"}
              alignItems="flex-start"
              style={{
                height: "100%",
                animationDelay: "0.1s",
                paddingTop: !sm?120:40,
              }}
              className={`animated ${md ? "fadeIn" : "fadeIn"} faster`}
            >
              <Grid item xs={12}>
                <Box pb={2} px={{ xs: 0, md: 0 }}>
                  <Typography
                    style={{
                      textAlign: "center",
                      lineHeight: 1.2,
                      color: "#fff",
                      textShadow: "0 4px 8px rgba(0,0,0,0.7)",
                      userSelect: "none",
                      textTransform: "none",
                      fontSize: !sm ? "3vw" : "3vh",
                        paddingBottom:!sm ? "initial" : 5,
                    }}
                    variant="h1"
                  >
                    Активный образ жизни с&nbsp;AlphaPet
                    <sup style={{ fontSize: "0.6em" }}>®</sup>
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "center",
                      lineHeight: 1.2,
                      color: "#FDD801",
                      fontWeight: 600,
                      textShadow: "0 4px 7px rgba(0,0,0,0.8)",
                      userSelect: "none",
                      textTransform: "none",
                      fontSize: !sm ? "1.50vw" : "2vh",
                    }}
                    variant="h1"
                  >
                    Поделитесь фото с&nbsp;питомцем на&nbsp;прогулке и&nbsp;получите шанс
                    выиграть призы
                  </Typography>
                </Box>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <Box textAlign={"center"} mt={2} mb={4}>*/}
              {/*    <Button*/}
              {/*      variant="contained"*/}
              {/*      color="primary"*/}
              {/*      className={classes.btn}*/}
              {/*      onClick={handleClick}*/}
              {/*    >*/}
              {/*      участвовать в Акции*/}
              {/*    </Button>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
            </Grid>
          </Container>
        </Box>

        <Box className={classes.body}>
          {/*<Suspense fallback="">*/}
          <RulesSection />
          <GallerySection />
          <PrizesSection />
          <WinnersSection />
          {/*</Suspense>*/}
        </Box>
      </Box>
    </Fade>
  );
});

export default PromoPage;
