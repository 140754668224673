import { useState, useEffect } from 'react'
import moment from 'moment'
import { Pets } from '@material-ui/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Box, Button, Collapse, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { addPet, updatePet } from 'api'
import { Snackbar } from 'components'
import {
  AutocompleteSelect,
  SelectInput,
  TextInput,
  FileInput,
  formLocale,
  PhoneInput,
  DateInput,
} from 'components/form-control'
import { ApiAnswerStatus, FormProps, Entity, OwnerPet, ApiAnswer } from 'types'
import { authAction } from 'store/auth'
import { propsAction } from 'store/props'
import { schema, acceptType } from './pet-validation'

const PetForm: React.FC<{ pet?: OwnerPet; rootModal?: string }> = ({ pet, rootModal }) => {
  const authDispatch = useDispatch()
  const dispatch = useReduxDispatch()
  const { breedTypes, breeds } = useReduxSelector((state) => state.props)
  const { notValid, serverError } = formLocale

  const [image, setImage] = useState<File>()
  const [breedsItems, setBreedsItems] = useState<Entity[]>([])
  const [breedPhone, setBreedPhone] = useState<boolean>(false)
  const [formProps, setFormProps] = useState<FormProps<any>>({
    data: {},
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const getBreedSpecie = (breed_id?: number) => {
    if (breed_id) {
      let res
      if (breeds) {
        for (let i = 0; i < breeds.length; i++)
          if (breeds[i].id === parseInt(breed_id?.toString(), 10)) {
            res = breeds[i]
            break
          }
      }
      return { id: res?.id, title: res?.title, key: res?.title }
    }
    return undefined
  }

  const hookForm = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...pet,
      feed: undefined,
      pet_birthday_at: pet?.pet_birthday_at ? moment(pet?.pet_birthday_at).format('YYYY-MM-DD') : undefined,
      nursery_recorder_id: pet?.breeder_phone ? 1 : undefined,
      breed_species_id: pet?.breed?.breed_species?.id,
      breed_id: getBreedSpecie(pet?.breed?.id),
    },
  })

  const {
    formState: { isValid },
  } = hookForm

  const getBreeds = (breed_species_id?: number) => {
    const res: Entity[] = []
    breeds?.forEach((b) => {
      if (b.breed_species.id === breed_species_id) res.push({ ...b, key: b.breed_species.key })
    })
    setBreedsItems(res)
  }

  const onSubmit = async (params: any) => {
    setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined }, processed: true })

    // Вытаскиваем id из объектов (select/autocomplete input)
    const props: { [x: string]: any } = {}
    // eslint-disable-next-line array-callback-return
    Object.keys(params).map((k) => {
      props[k] = typeof params[k] === 'object' ? params[k]?.id ?? params[k] : params[k]
    })

    const { status, message }: ApiAnswer<OwnerPet> = pet?.id
      ? await updatePet({ feed: props?.feed, image }, pet?.id)
      : await addPet({ ...props, image })
    setFormProps({ ...formProps, processed: false })

    switch (status) {
      case ApiAnswerStatus.SUCCESS:
        if (pet?.id) {
          authDispatch(authAction.auth())
          if (rootModal) dispatch(propsAction.modal({ modal: { [rootModal]: { show: false } } }))
        } else dispatch(propsAction.alert({ message, rootModal, onClose: () => authDispatch(authAction.auth()) }))
        break
      case ApiAnswerStatus.BREAK:
        dispatch(propsAction.alert({ message }))
        break
      default:
        setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: serverError } })
    }
  }

  const submitFailed = () => {
    setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: notValid } })
  }

  useEffect(() => {
    if (pet) {
      if (pet?.breeder_phone) setBreedPhone(true)
      getBreeds(pet?.breed?.breed_species?.id)
    }
  }, [pet])

  useEffect(() => {
    if (isValid)
      setFormProps({
        ...formProps,
        snackbar: { ...formProps.snackbar, message: undefined },
      })
  }, [isValid])

  return (
    <Box maxWidth={430} mx="auto">
      <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)}>
        <Box pt={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectInput
                name="breed_species_id"
                label="Питомец"
                items={breedTypes}
                form={hookForm}
                onChange={getBreeds}
                disabled={typeof pet !== 'undefined'}
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompleteSelect
                name="breed_id"
                label="Порода"
                defaultOptions={breedsItems}
                placeholder="Не выбрано"
                optionIcon={<Pets />}
                disabled={breedsItems.length === 0 || typeof pet !== 'undefined'}
                form={hookForm}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                name="pet_name"
                label="Кличка"
                form={hookForm}
                placeholder="Пример: Лаки"
                disabled={typeof pet !== 'undefined'}
              />
            </Grid>

            <Grid item xs={12}>
              <DateInput
                name="pet_birthday_at"
                label="Дата рождения"
                form={hookForm}
                min={new Date('1980-01-01')}
                disabled={typeof pet !== 'undefined'}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectInput
                name="nursery_recorder_id"
                label="Вы приобрели питомца у заводчика?"
                items={[
                  { id: 1, key: '1', title: 'Да' },
                  { id: 2, key: '0', title: 'Нет' },
                ]}
                onChange={(id) => setBreedPhone(id === 1)}
                disabled={typeof pet !== 'undefined'}
                form={hookForm}
              />
            </Grid>

            <Grid item xs={12}>
              <Collapse in={breedPhone}>
                <Box className={`animated ${breedPhone ? 'zoomIn' : 'zoomOut'} faster`}>
                  <PhoneInput
                    name="breeder_phone"
                    label="Телефон заводчика"
                    required={false}
                    form={hookForm}
                    disabled={!breedPhone || typeof pet !== 'undefined'}
                  />
                </Box>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <TextInput name="feed" label="Применяемый корм" form={hookForm} placeholder="Пример: AlphaPet: Puppy" />
            </Grid>

            <Grid item xs={12}>
              <FileInput
                name="image"
                acceptType={acceptType}
                onChange={({ target }) => setImage(target?.files?.[0])}
                label="Фото питомца"
                form={hookForm}
              />
            </Grid>

            <Grid item xs={12}>
              <Snackbar {...formProps.snackbar} />
            </Grid>

            <Grid item xs={12}>
              <Box width="100%" maxWidth={200} mx="auto" mt={3}>
                <Button fullWidth variant="contained" color="secondary" type="submit" disabled={formProps.processed}>
                  {typeof pet !== 'undefined' ? 'Готово' : 'Добавить'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}

export default PetForm
