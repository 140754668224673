import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Close,
  Favorite,
  FavoriteBorder,
  PlayArrow,
  Search,
  Sort,
} from "@material-ui/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntersection, useReduxDispatch, useReduxSelector } from "hooks";
import { propsAction } from "store/props";
import { ImageRenderer } from "components";
import { str } from "utils";
import { NumberInput, validation } from "components/form-control";
import { withPlanner, WithPlannerProps } from "components/hoc";
import {
  promoGalleryAll,
  promoGalleryUnvote,
  promoGalleryVote,
  promoPublicGalleryAll,
} from "api/promo";
import { ApiAnswerStatus, PromoGallery, PromoUserGalleryItem } from "types";
import { useStyles } from "../promo.style";
import { mediaViewerModalName } from "../modals";

export type GalleryForm = {
  phone?: string;
  content_type?: "all" | "video" | "photo" | null;
  gallery_id?: number;
  sort?: "date" | "-date" | "like" | "-like";
};

const GallerySection: React.FC<WithPlannerProps> = withPlanner(
  ({ planner }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useReduxDispatch();
    const { isAuth } = useReduxSelector((state) => state.auth);

    const galleryRef = useRef<HTMLDivElement>(null);

    const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));
    const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));

    const schema = validation({
      phone: yup.string(),
      gallery_id: yup.string().required(),
      sort: yup.mixed(),
    });

    const [inView, setInView] = useState(false);
    const [type, setType] = useState<"all" | "video" | "photo">("all");
    const [loading, setLoading] = useState(false);
    const [gallery, setGallery] = useState<PromoGallery>();
    const [galleryItems, setGalleryItems] = useState<PromoUserGalleryItem[]>(
      []
    );

    const [voteProcessig, setVoteProcessing] = useState(false);

    const [fetchError, setFetchError] = useState(true);

    const [isSearch, setIsSearch] = useState(false);

    const [sort, setSort] = useState<"date" | "-date" | "like" | "-like">(
      "-date"
    );

    useIntersection(galleryRef, () => {
      if (!inView) setInView(true);
    });

    const hookForm = useForm<any>({ resolver: yupResolver(schema) });
    const { reset, handleSubmit } = hookForm;

    const fetchAllGallery = async (props?: GalleryForm, page?: number) => {
      setLoading(true);
      const { data, status } = isAuth
        ? await promoGalleryAll({ sort: "-date", content_type: "all", ...props }, page)
        : await promoPublicGalleryAll(
            { sort: "-date", content_type: "all", ...props },
            page
          );
      setLoading(false);
      if (status === ApiAnswerStatus.SUCCESS && data) {
        setGallery(data);
        setFetchError(false);
        if (data?.data) {
          if (page) {
            const addGallery = data.data;
            setGalleryItems((p) => [...p, ...addGallery]);
          } else setGalleryItems(data.data);
        }
      } else {
        reset();
        setIsSearch(false);
        setGalleryItems([]);
        setFetchError(!true);
      }
    };

    const onSubmit = (props: GalleryForm) => {
      // Очистка поиска
      if (isSearch) {
        fetchAllGallery();
        setIsSearch(false);
        reset();
        return false;
      }

      let phone;
      let gallery_id;
      const value = props?.gallery_id?.toString();

      if (value) {
        if (/^\+?[789].{9,}/.test(value)) {
          const phoneDigits = value.replace(/\D/g, "");
          phone =
            phoneDigits.length > 10
              ? phoneDigits.substring(1, phoneDigits.length)
              : phoneDigits;
        } else gallery_id = parseInt(value, 10);
        setIsSearch(true);
      }

      fetchAllGallery({
        phone,
        gallery_id,
        sort,
        content_type: type === "all" ? "all" : type,
      });
      return true;
    };

    const vote = async (id: number, index: number, success?: () => void) => {
      setVoteProcessing(true);
      const { status, message } = await promoGalleryVote(id);
      setVoteProcessing(false);

      if (status === ApiAnswerStatus.SUCCESS) {
        setGalleryItems((g) => [
          ...g.slice(0, index),
          {
            ...g[index],
            unvote_access: true,
            vote_access: false,
            gallery_votes_count: (g[index]?.gallery_votes_count ?? 0) + 1,
            is_vote: true,
          },
          ...g.slice(index + 1),
        ]);
        if (success) success();
      } else {
        dispatch(propsAction.alert({ message }));
      }
    };

    const unvote = async (id: number, index: number, success?: () => void) => {
      setVoteProcessing(true);
      const { status, message } = await promoGalleryUnvote(id);
      setVoteProcessing(false);

      if (status === ApiAnswerStatus.SUCCESS) {
        setGalleryItems((g) => [
          ...g.slice(0, index),
          {
            ...g[index],
            unvote_access: false,
            vote_access: true,
            gallery_votes_count: (g[index]?.gallery_votes_count ?? 1) - 1,
            is_vote: false,
            pet_name: "",
          },
          ...g.slice(index + 1),
        ]);
        if (success) success();
      } else {
        dispatch(propsAction.alert({ message }));
      }
    };

    const toggleSort = (variant: "date" | "like") => {
      setSort((p) => (p === variant ? `-${variant}` : variant));
    };

    const showNoAuthAlert = (rootModal?: string) => {
      dispatch(
        propsAction.alert({
          message: {
            info: [
              "Для того, чтобы принять участие в голосовании просим Вас авторизоваться.",
            ],
          },
          actions: [
            {
              title: "Войти",
              onAction: () => history.push({ search: "signin" }),
            },
          ],
          rootModal,
        })
      );
    };

    const showNoVoteAccessAlert = (rootModal?: string) => {
      dispatch(
        propsAction.alert({
          message: {
            info: [
              "Проголосовать могут только пользователи с зарегистрированным валидным чеком.",
            ],
          },

          rootModal,
        })
      );
    };

    const showGalleryItemModal = (
      params?: PromoUserGalleryItem,
      index?: number
    ) => {
      dispatch(
        propsAction.modal({
          modal: {
            [mediaViewerModalName]: {
              show: true,
              params: { ...params, vote, unvote, index, showNoAuthAlert },
            },
          },
        })
      );
    };

    useEffect(() => {
      if (sort || type) {
        if (planner?.clearTimeouts()) {
          planner?.timeout(() => {
            onSubmit({ sort, content_type: type });
          }, 300);
        }
      }
    }, [sort, type]);

    useEffect(() => {
      if (inView) fetchAllGallery()
    }, [inView])

    return (
      <Box py={{ xs: 5, sm: 8 }}>
        <Collapse in={!fetchError}>
          <Container maxWidth="xl">
            <Box fontSize={{ xs: 24, md: 45 }}>
              <Typography
                variant="h2"
                style={{
                  marginTop: 0,
                  fontSize: "inherit",
                  textTransform: "uppercase",
                }}
              >
                Галерея работ
              </Typography>
            </Box>

            <Box mb={{ xs: 3, sm: 6 }}>
              <Grid
                container
                spacing={md ? 0 : 2}
                alignItems="center"
                justifyContent={!md ? "space-between" : "center"}
              >
                <Grid item xs={12} md={4}>
                  <Box
                    fontSize={{ xs: 20, md: 18, lg: 22 }}
                    pb={{ xs: 4, md: 0, lg: 0 }}
                    height="100%"
                  >
                    <Grid
                      container
                      justifyContent={!md ? "flex-start" : "center"}
                      alignItems="center"
                      spacing={4}
                      style={{ height: "100%" }}
                    >
                      <Grid item xs="auto">
                        <Typography
                          variant="h2"
                          style={{
                            fontWeight: 600,
                            margin: 5,
                            fontSize: "inherit",
                            textTransform: "uppercase",
                            color: type === "all" ? "#323E48" : "#999A99",
                            cursor: type !== "all" ? "pointer" : "default",
                          }}
                          onClick={() => setType("all")}
                        >
                          Все
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography
                          variant="h2"
                          style={{
                            fontWeight: 600,
                            margin: 5,
                            fontSize: "inherit",
                            textTransform: "uppercase",
                            color: type === "photo" ? "#323E48" : "#999A99",
                            cursor: type !== "photo" ? "pointer" : "default",
                          }}
                          onClick={() => setType("photo")}
                        >
                          Фото
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography
                          variant="h2"
                          style={{
                            fontWeight: 600,
                            margin: 5,
                            fontSize: "inherit",
                            textTransform: "uppercase",
                            color: type === "video" ? "#323E48" : "#999A99",
                            cursor: type !== "video" ? "pointer" : "default",
                          }}
                          onClick={() => setType("video")}
                        >
                          Видео
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={8} md={4} lg={4}>
                  <Box
                    maxWidth={sm ? "initial" : 402}
                    mx="auto"
                    mb={{ xs: 2, md: 0 }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <NumberInput
                        name="gallery_id"
                        form={hookForm}
                        disabled={loading}
                        placeholder="Поиск по телефону/№ работы"
                        label=""
                        style={{ marginBottom: 0 }}
                        InputProps={{
                          fullWidth: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box color="text.primary">
                                <IconButton
                                  type="submit"
                                  aria-label="search-button"
                                  color="inherit"
                                  edge="end"
                                >
                                  {isSearch ? <Close /> : <Search />}
                                </IconButton>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    spacing={md ? 1 : 1}
                    justifyContent={md ? "center" : "flex-end"}
                    style={{ width: "100%" }}
                  >
                    {/*<Grid item>*/}
                    {/*  <Box*/}
                    {/*    className={`${classes.sortBtn} ${*/}
                    {/*      isSearch && "disabled"*/}
                    {/*    }`}*/}
                    {/*    onClick={() => !isSearch && toggleSort("like")}*/}
                    {/*  >*/}
                    {/*    <span>по количеству лайков</span>{" "}*/}
                    {/*    <Sort*/}
                    {/*      fontSize="small"*/}
                    {/*      style={{*/}
                    {/*        transform: `scaleY(${sort === "like" ? -1 : 1})`,*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </Box>*/}
                    {/*</Grid>*/}
                    <Grid item>
                      <Box
                        className={`${classes.sortBtn} ${
                          isSearch && "disabled"
                        }`}
                        onClick={() => !isSearch && toggleSort("date")}
                      >
                        <span>по дате создания</span>{" "}
                        <Sort
                          fontSize="small"
                          style={{
                            transform: `scaleY(${sort === "date" ? -1 : 1})`,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box position="relative">
              {!loading && galleryItems.length === 0 && (
                <Box textAlign="center">
                  <Typography variant="body2">Ничего не найдено</Typography>
                </Box>
              )}

              <div ref={galleryRef}>
                <Collapse in={galleryItems.length > 0} timeout={500} appear>
                  <Box>
                    <Fade in={galleryItems.length > 0} appear>
                      <Grid container spacing={sm ? 3 : 4}>
                        {galleryItems?.map((item, idx) => {
                          const {
                            id,
                            gallery_votes_count,
                            content,
                            content_type,
                            is_vote,
                            vote_access,
                            created_at,
                          } = {
                            ...item,
                          };

                          const {
                            thumb_src_link,
                            history,
                            small_src_link,
                            pet_name,
                          } = content || {};

                          return (
                            <Grid
                              key={`media_${idx}_${id}`}
                              item
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Box
                                height={{ xs: 220, md: 306 }}
                                className={classes.galleryImage}
                                onClick={() => showGalleryItemModal(item, idx)}
                                title={moment(created_at).format(
                                  "DD.MM.YYYY HH:mm"
                                )}
                              >
                                <ImageRenderer
                                  url={thumb_src_link ?? ""}
                                  thumb={small_src_link ?? ""}
                                  cover
                                />

                                {content_type === "video" && (
                                  <Box className="video_overlay">
                                    <PlayArrow
                                      fontSize="inherit"
                                      style={{
                                        border: "1px solid #fff",
                                        borderRadius: 30,
                                      }}
                                      color="inherit"
                                    />
                                  </Box>
                                )}
                              </Box>

                              <Box mt={sm ? 1 : 2} overflow="hidden">
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent={"space-between"}
                                  wrap="nowrap"
                                >
                                  <Grid item>
                                    <Box
                                      fontWeight={600}
                                      fontSize={{ xs: 14, md: 20 }}
                                      className="nowrap_txt"
                                      maxWidth={190}
                                      lineHeight={1.1}
                                    >
                                      {str.format.firstToUpper(pet_name ?? "")}
                                    </Box>
                                  </Grid>

                                  <Grid item>
                                    <Box
                                      fontWeight={500}
                                      color="#C1C3C5"
                                      pl={1}
                                      lineHeight={1.1}
                                      fontSize={{ xs: 14, md: 18 }}
                                    >
                                      №&nbsp;{id}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                              {/*    {isAuth &&*/}
                              {/*    vote_access === null &&*/}
                              {/*    !is_vote ? (*/}
                              {/*      <></>*/}
                              {/*    ) : (*/}
                              {/*      <Grid item style={{ marginLeft: "auto" }}>*/}
                              {/*        <Box*/}
                              {/*          fontSize={{ xs: 16, sm: 22 }}*/}
                              {/*          style={{*/}
                              {/*            display: "flex",*/}
                              {/*            alignItems: "center",*/}
                              {/*            flexWrap: "wrap",*/}
                              {/*          }}*/}
                              {/*          color={*/}
                              {/*            is_vote ? "error.main" : "initial"*/}
                              {/*          }*/}
                              {/*        >*/}
                              {/*          <span*/}
                              {/*            style={{*/}
                              {/*              fontSize: sm ? 14 : 16,*/}
                              {/*              marginRight: 2,*/}
                              {/*              marginLeft: 2,*/}
                              {/*              fontWeight: 400,*/}
                              {/*            }}*/}
                              {/*          >*/}
                              {/*            {gallery_votes_count}*/}
                              {/*          </span>*/}

                              {/*          <IconButton*/}
                              {/*            color="inherit"*/}
                              {/*            style={{*/}
                              {/*              padding: 3,*/}
                              {/*              marginRight: -3,*/}
                              {/*            }}*/}
                              {/*            onClick={() => {*/}
                              {/*              // if (isAuth) {*/}
                              {/*              //   if (id && !voteProcessig) {*/}
                              {/*              //     if (vote_access && !is_vote)*/}
                              {/*              //       vote(id, idx);*/}
                              {/*              //     if (!vote_access && is_vote)*/}
                              {/*              //       unvote(id, idx);*/}
                              {/*              //     if (!vote_access && !is_vote)*/}
                              {/*              //       showNoVoteAccessAlert();*/}
                              {/*              //   }*/}
                              {/*              // } else showNoAuthAlert();*/}
                              {/*            }}*/}
                              {/*            className={classes.likeButton}*/}
                              {/*          >*/}
                              {/*            {is_vote ? (*/}
                              {/*              <Favorite*/}
                              {/*                color="inherit"*/}
                              {/*                fontSize={sm ? "small" : "medium"}*/}
                              {/*              />*/}
                              {/*            ) : (*/}
                              {/*              <FavoriteBorder*/}
                              {/*                color="inherit"*/}
                              {/*                fontSize={sm ? "small" : "medium"}*/}
                              {/*              />*/}
                              {/*            )}*/}
                              {/*          </IconButton>*/}
                              {/*        </Box>*/}
                              {/*      </Grid>*/}
                              {/*    )}*/}
                              {/*  </Grid>*/}
                              {/*</Box>*/}

                              {/*<Box>*/}
                              {/*  <Box*/}
                              {/*    sx={{*/}
                              {/*      height: 50,*/}
                              {/*      overflow: "hidden",*/}
                              {/*      textOverflow: "dotted",*/}
                              {/*    }}*/}
                              {/*    pt={2}*/}
                              {/*  >*/}
                              {/*    {str.normalize(history ?? "")}*/}
                              {/*  </Box>*/}
                              {/*  ...*/}
                              {/*  <Box pt={2}>*/}
                              {/*    <a*/}
                              {/*      href="#"*/}
                              {/*      onClick={(ex) => {*/}
                              {/*        ex.preventDefault();*/}
                              {/*        showGalleryItemModal(item, idx);*/}
                              {/*      }}*/}
                              {/*    >*/}
                              {/*      Читать больше...*/}
                              {/*    </a>*/}
                              {/*  </Box>*/}
                              {/*</Box>*/}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Fade>
                  </Box>
                </Collapse>
              </div>

              {gallery?.next_page_url &&
              gallery?.per_page &&
              gallery?.total &&
              gallery.per_page < gallery.total ? (
                <Box textAlign="center" mt={8} maxWidth={260} mx="auto">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (gallery?.current_page && gallery.next_page_url)
                        fetchAllGallery({ sort }, gallery.current_page + 1);
                    }}
                    fullWidth
                  >
                    Показать еще
                  </Button>
                </Box>
              ) : (
                <></>
              )}

              <Fade in={loading}>
                <Box
                  position="absolute"
                  textAlign="center"
                  bgcolor="rgba(255,255,255,0.5)"
                  left={0}
                  top={0}
                  bottom={0}
                  right={0}
                >
                  <Box my={3}>
                    <CircularProgress />
                  </Box>
                </Box>
              </Fade>
            </Box>
          </Container>
        </Collapse>
      </Box>
    );
  }
);

export default GallerySection;
