import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Theme,
  Typography,
  Collapse,
  Grow,
  Grid,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import { AlertProps } from 'types'
import { str } from 'utils'
import CloseButton from '../dialog/close-button'
import { withPlanner, WithPlannerProps } from '../hoc'

const Transition: React.ForwardRefExoticComponent<any> = forwardRef(
  (props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => <Grow ref={ref} {...props} />
)

export const Alert: React.FC<AlertProps & WithPlannerProps> = withPlanner((props) => {
  const {
    title = 'Информация',
    fullScreen = false,
    closeButton = true,
    actions = [{ title: 'Готово', variant: 'contained', color: 'secondary' }],
    message = undefined,
    onClose,
    rootModal,
    planner,
  } = props

  const dispatch = useReduxDispatch()
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xs'))

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    if (onClose) onClose()
    planner?.timeout(() => {
      dispatch(propsAction.alert({}))
    }, 200)
  }

  useEffect(() => {
    if (message) {
      setOpen(true)
      if (rootModal) dispatch(propsAction.modal({ modal: { [rootModal]: { show: false, params: undefined } } }))
    }
  }, [message])

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen || sm}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') handleClose()
      }}
      aria-labelledby="global-alert"
      scroll="body"
      maxWidth="sm"
      open={open}
    >
      <DialogTitle id="alert-title" disableTypography>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>

      {closeButton && <CloseButton close={handleClose} disabled={false} />}

      <DialogContent id="alert-content">
        <Collapse in={open} timeout={250}>
          <Box>
            <Grow in={open} style={{ transformOrigin: '50% 0 0' }} timeout={{ enter: 650, exit: 180 }}>
              <Box>
                {!!message &&
                  Object.entries(message)
                    .filter(([key, val]) => key !== 'exception')
                    .map(([_, val], idx) => (
                      <Box key={`alert-message-${idx}`}>
                        {Array.isArray(val)
                          ? val.map((v, i) => (
                              <p className="alert_msg" key={i}>
                                {str.normalize(v, true)}
                              </p>
                            ))
                          : typeof val === 'string' && <p className="alert_msg">{str.normalize(val, true)}</p>}
                      </Box>
                    ))}
              </Box>
            </Grow>
          </Box>
        </Collapse>
      </DialogContent>

      {actions.length > 0 && (
        <DialogActions id="alert-actions">
          <Grid container justifyContent="center" spacing={2}>
            {actions.map(
              (
                {
                  title: btnTitle,
                  variant = 'contained',
                  color = 'secondary',
                  onAction,
                  breakpoints = { xs: 10, sm: 6 },
                  ...btnProps
                },
                idx
              ) => (
                <Grid key={idx} item {...breakpoints}>
                  <Button
                    onClick={() => {
                      if (onAction) {
                        onAction()
                        handleClose()
                      } else handleClose()
                    }}
                    variant={variant}
                    color={color}
                    {...btnProps}
                    fullWidth
                  >
                    {btnTitle}
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  )
})
