import { useState, useEffect } from 'react'
import { Container, Grid, Box, Hidden, useScrollTrigger, useMediaQuery, Slide, Theme } from '@material-ui/core'
import useReactRouter from 'use-react-router'
import { MobileMenu, Menu } from './menu'
import { useStyles } from './header.style'

export const Header: React.FC = () => {
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))
  const trigger = useScrollTrigger({ target: window })
  const { location } = useReactRouter()
  const { pathname } = location
  const classes = useStyles()

  const [classScrollHeader, setClassScrollHeader] = useState<'' | 'on_scroll_header'>('')
  const [classWhiteHeader, setClassWhiteHeader] = useState<'contest' | 'white'>('white')

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      e.preventDefault()
      if (window.pageYOffset >= 50 && !md) setClassScrollHeader('on_scroll_header')
      else setClassScrollHeader('')
    })
  }, [])

  useEffect(() => {
    return setClassWhiteHeader(
        (pathname !== "/contest") ? "white" : "contest"
    );
  }, [pathname])

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <Box className={[classes.header, classScrollHeader, classWhiteHeader].join(' ')}>
        <Container maxWidth={false} style={{ height: '100%' }}>
          <Box display="flex" alignItems="center" height="100%">
            <Hidden smDown>
              <Menu />
            </Hidden>
            <Hidden mdUp>
              <Grid container alignItems="center" alignContent="center" justifyContent="space-around">
                <MobileMenu />
              </Grid>
            </Hidden>
          </Box>
        </Container>
      </Box>
    </Slide>
  )
}
